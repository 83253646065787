import { Col, Row, Space, Image, Typography, Button, List } from "antd";
import { capitalizeFirstLetter } from "../../utils/utility";

const { Text } = Typography;

const ConversationWHATSAPP = ({ transcript }) => {
  const isFlowsMessage =
    transcript.is_from_user &&
    transcript.message?.[0] === "{" &&
    transcript.message[transcript.message.length - 1] === "}";

  const convertedFlowsMessage = {};

  if (isFlowsMessage) {
    const parsedMessage = JSON.parse(transcript.message);
    Object.entries(parsedMessage).forEach(([key, value]) => {
      if (key === "flow_token" || typeof value !== "string") return;
      convertedFlowsMessage[key] = value;
    });
  }

  return (
    <Space size={10} style={{ alignItems: "start" }}>
      {transcript.content && (
        <Row>
          <Col span={24}>
            <Space
              size={10}
              direction="vertical"
              style={{
                width: 300,
                border: "1px solid #C0C0C0",
                padding: 10,
                borderRadius: 10,
              }}
            >
              {transcript.content.header && transcript.content.header.image && (
                <Image
                  preview={false}
                  src={transcript.content.header.image.link}
                />
              )}
              {transcript.content.header && transcript.content.header.text && (
                <Text strong>{transcript.content.header.text}</Text>
              )}
              {transcript.content.body && (
                <Text>{transcript.content.body.text}</Text>
              )}
              {transcript.content.action?.buttons &&
                transcript.content.action.buttons.map((button, index) => {
                  return (
                    <Button style={{ borderRadius: 5 }} key={index}>
                      {button.reply.title}
                    </Button>
                  );
                })}
              {transcript.content.action?.name === "flow" &&
                transcript.content.action?.parameters?.flow_cta && (
                  <Button style={{ borderRadius: 5 }}>
                    {transcript.content.action?.parameters?.flow_cta}
                  </Button>
                )}
            </Space>
          </Col>
        </Row>
      )}

      {/* For WhatsApp flows the message is sent as a stringified JSON object */}
      {isFlowsMessage && (
        <Space
          size={7}
          direction="vertical"
          style={{
            width: 300,
            border: "1px solid #C0C0C0",
            padding: 10,
            borderRadius: 10,
          }}
        >
          {Object.entries(convertedFlowsMessage).map(([key, value]) => (
            <Space size={10} direction="horizontal">
              <Text strong>{capitalizeFirstLetter(key)} :</Text>
              <Text>
                {typeof value === "string"
                  ? value
                  : JSON.stringify(value, null, 2)}
              </Text>
            </Space>
          ))}
        </Space>
      )}
    </Space>
  );
};

export default ConversationWHATSAPP;
