import { useEffect, useState } from "react";
import {
  Input,
  Row,
  Col,
  Typography,
  Space,
  Divider,
  Button,
  Form,
  Switch,
  List,
} from "antd";
import { useGlobalContext } from "../../../contexts/globalContext";
import {
  responseConvertedCustomFields,
  responseDropdownColumns,
  responseFieldsToInclude,
  systemResponseProperties,
} from "../../../constants/enums";
import { FullPageLoader } from "../../../shared-components/LoadingIndicator";
import NoData from "../../../shared-components/NoData";
import { mode } from "../../../constants/env";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RxDragHandleDots2 } from "react-icons/rx";

const { Text } = Typography;

const ResponsesForm = () => {
  const [state, , dispatchMiddleware] = useGlobalContext();
  const [form] = Form.useForm();
  const [arrangeResponsesProperties, setArrangeResponsesProperties] = useState(
    []
  );
  const [mouseHover, setMouseHover] = useState();

  let enums = state.application.enums?.responses?.data || [];

  // Convert responses properties to array of data
  let responsesProperties = state.responseSettings.responseProperties.flatMap(
    (responseProperty) =>
      [
        ...new Set([
          ...responseProperty?.allproperties?.filter(
            (property) => !systemResponseProperties.includes(property)
          ),
          ...responseDropdownColumns,
        ]),
      ].map((property) => ({ key: property }))
  );

  //Check column contains chart
  //responseFieldsToInclude from enums
  if (
    ["DEV", "STAGING"].includes(mode) &&
    state.responseSettings.currentResponseCategory?.toLowerCase() === "feedback"
  ) {
    responsesProperties.push({
      key: "chart",
    });
  }

  //Merge responses properties and enums based on id
  responsesProperties = responsesProperties.map((responseProperty) => {
    const matchedEnum = enums.find(
      (enumKey) =>
        enumKey._id.toLowerCase() === responseProperty.key.toLowerCase()
    );

    if (matchedEnum) {
      responseProperty.display_name = matchedEnum.display_name;
      responseProperty.display_order = matchedEnum.display_order;
      responseProperty.isGlobal = matchedEnum.is_global;
      responseProperty.display = matchedEnum.display;
      responseProperty.isFilter = matchedEnum.filter;
      responseProperty.width = matchedEnum.width ?? 100;
    }
    return responseProperty;
  });

  //For status and assigned fields, set isGlobal to false to show/hide dropdown in response header
  responsesProperties.forEach((responseProperty) => {
    if (responseConvertedCustomFields.includes(responseProperty.key)) {
      responseProperty.isGlobal = false; // Update isGlobal property
    }
  });

  //Filter custom fields
  // const customFields = responsesProperties.filter(({ isGlobal }) => !isGlobal);

  //Filter system fields
  // const systemFields = responsesProperties.filter(
  //   ({ isGlobal, display }) => isGlobal && display !== false
  // );

  // //Combain custom, system fields
  // responsesProperties = systemFields.concat(customFields);

  //Sort responses properties based on display order
  responsesProperties = responsesProperties.sort((a, b) => {
    if (a.display_order && b.display_order) {
      return (a.display_order || 0) - (b.display_order || 0);
    } else {
      return (b.display_order || 0) - (a.display_order || 0);
    }
  });

  //Form set fields value
  useEffect(() => {
    let recordToBeEdited = responsesProperties.map((responseProperty) => ({
      [responseProperty.key + "~*!*$*~display_name"]:
        responseProperty.display_name,
      [responseProperty.key + "~*!*$*~display"]: responseProperty.display,
      [responseProperty.key + "~*!*$*~filter"]: responseProperty.isFilter,
    }));
    recordToBeEdited = Object.assign({}, ...recordToBeEdited);

    form.setFieldsValue(recordToBeEdited);
  }, []);

  const handleFormSubmit = (e) => {
    // Convert object into array of data
    let enumValues = Object.entries(e)
      .map(([key, value]) => {
        const [_id, columns] = key.split("~*!*$*~");
        return {
          _id,
          [columns]: value,
        };
      })
      .reduce((enumValues, currentEnum) => {
        enumValues[currentEnum._id] ??= {
          _id: currentEnum._id,
        };
        Object.assign(enumValues[currentEnum._id], currentEnum);
        return enumValues;
      }, {});
    enumValues = Object.values(enumValues);
    // Add width properties
    enumValues = enumValues.map((enumValue) => {
      arrangeResponsesProperties.forEach((responseProperty) => {
        if (responseProperty.key === enumValue._id) {
          enumValue.width = responseProperty.width;
          enumValue.display_order = responseProperty.display_order;
        }
      });
      return enumValue;
    });
    setArrangeResponsesProperties(responsesProperties);

    enumValues = enumValues.map((enumValue) => {
      enumValue.module = "responses";
      return enumValue;
    });

    dispatchMiddleware({
      type: "MWD_ADD_MULTIPLE_ENUMS",
      payload: {
        enumValues,
        component: "response-settings-form",
      },
    });
  };

  useEffect(() => {
    setArrangeResponsesProperties(responsesProperties);
  }, [state.apiCallStatus ?? ["response-settings-form"]?.loading]);

  return (
    <>
      {state.apiCallStatus["response-settings-form"] &&
      state.apiCallStatus["response-settings-form"].loading ? (
        <FullPageLoader
          component="response-settings-form"
          loadingMessage="Submitting... Please wait..."
        />
      ) : (
        <Form
          form={form}
          onFinish={handleFormSubmit}
          autoComplete="off"
          layout="vertical"
        >
          <Row style={{ marginLeft: "56%" }}>
            <Col span={24}>
              {arrangeResponsesProperties.length !== 0 ? (
                <DragDropContext
                  onDragEnd={(result) => {
                    const { source, destination, type } = result;
                    if (!destination) return;
                    if (
                      source.droppableId === destination.droppableId &&
                      source.index === destination.index
                    )
                      return;

                    if (type === "group") {
                      const rearrangedResponse = [
                        ...arrangeResponsesProperties,
                      ];

                      const sourceIndex = source.index;
                      const destinationIndex = destination.index;

                      // While drag
                      const [removeResponse] = rearrangedResponse.splice(
                        sourceIndex,
                        1
                      );
                      // While drop
                      rearrangedResponse.splice(
                        destinationIndex,
                        0,
                        removeResponse
                      );
                      // Add index +1 for display order
                      const updatedResponseOrder = rearrangedResponse.map(
                        (response, index) => ({
                          ...response,
                          display_order: index + 1,
                        })
                      );
                      setArrangeResponsesProperties(updatedResponseOrder);
                    }
                  }}
                >
                  <Droppable
                    droppableId="list"
                    type="group"
                    onMouseEnter={() => {
                      setMouseHover();
                    }}
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <Row>
                          <Col
                            span={4}
                            style={{
                              textAlign: "end",
                            }}
                          >
                            <Text strong> Show </Text>
                          </Col>

                          <Col
                            span={15}
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <Text strong>Display Name</Text>
                          </Col>

                          <Col
                            span={5}
                            style={{
                              textAlign: "end",
                            }}
                          >
                            <Text strong>Filterable</Text>
                          </Col>
                        </Row>
                        <List
                          dataSource={arrangeResponsesProperties.filter(
                            ({ key }) => key !== "chart"
                          )}
                          renderItem={(responseProperty, index) => (
                            <Draggable
                              draggableId={responseProperty.key}
                              index={index}
                              key={responseProperty.key}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Row
                                    align="middle"
                                    key={responseProperty.key}
                                    style={{
                                      borderRadius: "5px",
                                      padding: "10px 4px 0px 0px",
                                      backgroundColor:
                                        mouseHover === index ? "#f5f5f5" : "",
                                      marginBottom: "5px",
                                      height: "55px",
                                    }}
                                    onMouseEnter={() => {
                                      setMouseHover(index);
                                    }}
                                    onMouseLeave={() => {
                                      setMouseHover();
                                    }}
                                  >
                                    <Col span={2}>
                                      {mouseHover === index ? (
                                        <RxDragHandleDots2
                                          size={23}
                                          style={{
                                            // cursor: "move",
                                            color: "rgb(145 147 149)",
                                            marginBottom: "15px",
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                    <Col span={4}>
                                      <Form.Item
                                        name={
                                          responseProperty.key +
                                          "~*!*$*~display"
                                        }
                                      >
                                        <Switch
                                          // disabled={responseProperty.isGlobal}
                                          checked={responseProperty.display}
                                          onChange={(checked) => {
                                            form.setFieldsValue({
                                              [responseProperty.key +
                                              "~*!*$*~display"]: checked,
                                            });
                                            setArrangeResponsesProperties(
                                              arrangeResponsesProperties.map(
                                                (response) => {
                                                  if (
                                                    response.key ===
                                                    responseProperty.key
                                                  ) {
                                                    response.display = checked;
                                                  }
                                                  return response;
                                                }
                                              )
                                            );
                                          }}
                                          size="small"
                                        />
                                      </Form.Item>
                                    </Col>

                                    <Col span={15}>
                                      <Form.Item
                                        name={
                                          responseProperty.key +
                                          "~*!*$*~display_name"
                                        }
                                        rules={[
                                          {
                                            max: 100,
                                            message:
                                              "Display name should not exceed 100 characters",
                                          },
                                        ]}
                                      >
                                        <Input
                                          style={{
                                            width: "90%",
                                            height: "30px",
                                          }}
                                          disabled={
                                            !state.currentUser.permission
                                              .ACCOUNT_RESPONSES_EDIT ||
                                            responseProperty.isGlobal ||
                                            responseConvertedCustomFields
                                              .concat(responseFieldsToInclude)
                                              .includes(responseProperty.key)
                                          }
                                          defaultValue={
                                            responseProperty.display_name ||
                                            responseProperty.key
                                          }
                                          onChange={(e) => {
                                            if (e.target.value === "") {
                                              form.setFieldsValue({
                                                [responseProperty.key +
                                                "~*!*$*~display_name"]:
                                                  responseProperty.key,
                                              });
                                            }
                                          }}
                                        />
                                      </Form.Item>
                                    </Col>

                                    <Col
                                      span={3}
                                      style={{
                                        textAlign: "end",
                                      }}
                                    >
                                      <Form.Item
                                        name={
                                          responseProperty.key + "~*!*$*~filter"
                                        }
                                      >
                                        <Switch
                                          checked={responseProperty.isFilter}
                                          disabled={responseFieldsToInclude.includes(
                                            responseProperty.key
                                          )}
                                          onChange={(checked) => {
                                            form.setFieldsValue({
                                              [responseProperty.key +
                                              "~*!*$*~filter"]: checked,
                                            });
                                            setArrangeResponsesProperties(
                                              arrangeResponsesProperties.map(
                                                (response) => {
                                                  if (
                                                    response.key ===
                                                    responseProperty.key
                                                  ) {
                                                    response.isFilter = checked;
                                                  }
                                                  return response;
                                                }
                                              )
                                            );
                                          }}
                                          size="small"
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            </Draggable>
                          )}
                        />
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <Row>
                  <NoData />
                </Row>
              )}
            </Col>
          </Row>

          {["DEV", "STAGING"].includes(mode) &&
            state.responseSettings.currentResponseCategory?.toLowerCase() ===
              "feedback" && (
              <>
                <Divider />

                <Row>
                  <Col span={9}>
                    <Space direction="vertical">
                      <Text strong>Trend Analysis</Text>
                      <Text>
                        Improve your business using trend data to inform
                        <br />
                        your decision-making
                      </Text>
                    </Space>
                  </Col>

                  <Col
                    span={10}
                    style={{
                      marginLeft: 30,
                    }}
                  >
                    <Form.Item
                      name="chart~*!*$*~display"
                      label="Show Trend Analysis"
                    >
                      <Switch
                        disabled={
                          !state.currentUser.permission.ACCOUNT_RESPONSES_EDIT
                        }
                        defaultChecked={
                          responsesProperties.find(
                            (responseProperty) =>
                              responseProperty.key === "chart"
                          )?.display
                        }
                        onChange={(checked) => {
                          form.setFieldsValue({
                            "chart~*!*$*~display": checked,
                          });
                        }}
                        size="small"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

          <Divider />

          {arrangeResponsesProperties.length !== 0 && (
            <Row gutter={24}>
              <Col span={24}>
                <Space>
                  <Form.Item>
                    <Button
                      disabled={
                        !state.currentUser.permission.ACCOUNT_RESPONSES_EDIT
                      }
                    >
                      Cancel
                    </Button>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      disabled={
                        !state.currentUser.permission.ACCOUNT_RESPONSES_EDIT
                      }
                      type="primary"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          )}
        </Form>
      )}
    </>
  );
};

export default ResponsesForm;
