import { useGlobalContext } from "../../contexts/globalContext";
import { URIs } from "../../constants/env";
import {
  Row,
  Col,
  Avatar,
  Card,
  Typography,
  Button,
  Tooltip,
  Tag,
  Tabs,
  Space,
  Image,
  Empty,
} from "antd";
import {
  UserOutlined,
  CloseOutlined,
  ShareAltOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { ImLocation } from "react-icons/im";
import ContactManagerFormPanel from "../contact-manager/ContactManagerFormPanel";
import {
  botProfilePictures,
  channels,
  channelList,
} from "../../constants/enums";
import { getUserDateTime, extractInitialsFromName } from "../../utils/utility";
import { appInsights } from "../../AppInsights";
import { mode } from "../../constants/env";
import { FullPageLoader } from "../../shared-components/LoadingIndicator";
import { useEffect } from "react";

const { Text } = Typography;

const ConversationInfoPanel = ({ isContactAlreadyExist }) => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  let currentConversation = {
    ...state.conversations.currentConversation,
    ...(state.conversations.conversationUserState || {}),
  };

  //Changing channel name to upperCase
  let currentConversationChannelName =
    currentConversation && currentConversation?.channel?.toUpperCase();

  let currentConversationChannel = channelList.find(
    (channel) => channel._id === currentConversationChannelName
  );

  if (currentConversationChannel) {
    currentConversationChannel = {
      ...currentConversationChannel,
      ...currentConversation,
    };
    //concating + before WhatsApp number
    currentConversationChannel.conversation_id =
      currentConversationChannelName === "WHATSAPP"
        ? "+".concat(currentConversation.conversation_id)
        : currentConversation.conversation_id;
  }

  isContactAlreadyExist = state.contactManager.contacts?.find((contact) =>
    Object.values(contact).includes(currentConversationChannel?.conversation_id)
  );

  //Current conversation bot details
  const currentConversationBotDetails = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === currentConversation?.bot_id
  );

  const tabPane = [
    {
      key: "1",
      label: <UserOutlined style={{ fontSize: 15, marginLeft: 7 }} />,
      children: isContactAlreadyExist && (
        <>
          <Text strong>About</Text>
          <div style={{ marginTop: 15 }}>
            <Row gutter={[0, 20]}>
              {isContactAlreadyExist.email && (
                <>
                  <Col span={8}>
                    <Text>Email</Text>
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={15}>
                    <Tooltip title={isContactAlreadyExist.email}>
                      <Text ellipsis>{isContactAlreadyExist.email}</Text>
                    </Tooltip>
                  </Col>
                </>
              )}
              {isContactAlreadyExist.whatsappId && (
                <>
                  <Col span={8}>
                    <Text>Phone</Text>
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={15}>
                    <Text>{isContactAlreadyExist.whatsappId}</Text>
                  </Col>
                </>
              )}
              <Col span={8}>
                <Text>Subscribed</Text>
              </Col>
              <Col span={1}>:</Col>
              <Col span={15}>
                {isContactAlreadyExist.isUnsubscribed ? (
                  <Tag color="red">No</Tag>
                ) : (
                  <Tag color="success">Yes</Tag>
                )}
              </Col>

              {isContactAlreadyExist.tags && (
                <>
                  <Col span={8}>
                    <Text>Tags</Text>
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={15} style={{ height: "70px", overflow: "auto" }}>
                    {isContactAlreadyExist.tags.split(",").map((tag, index) => (
                      <Tag
                        color="blue"
                        key={index}
                        type="round"
                        style={{ borderRadius: "4px", marginBottom: 8 }}
                      >
                        {tag.trim()}
                      </Tag>
                    ))}
                  </Col>
                </>
              )}
            </Row>
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: <ShareAltOutlined style={{ fontSize: 15, marginLeft: 7 }} />,
      children: isContactAlreadyExist && (
        <>
          <Text strong>Social Media</Text>
          <div style={{ marginTop: 15 }}>
            <Row gutter={[0, 20]}>
              {isContactAlreadyExist.whatsappId && (
                <>
                  <Col span={5} style={{ alignItems: "center" }}>
                    <Image
                      src={channels.WHATSAPP.logo}
                      width={20}
                      height={20}
                      preview={false}
                    />
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={18}>
                    <Tooltip title={isContactAlreadyExist.whatsappId}>
                      <Text ellipsis>{isContactAlreadyExist.whatsappId}</Text>
                    </Tooltip>
                  </Col>
                </>
              )}

              {isContactAlreadyExist.msTeamsId && (
                <>
                  <Col span={5} style={{ alignItems: "center" }}>
                    <Image
                      src={channels.MSTEAMS.logo}
                      width={20}
                      height={20}
                      preview={false}
                    />
                  </Col>
                  <Col span={1}>:</Col>
                  <Col
                    span={18}
                    style={{
                      width: 100,
                    }}
                  >
                    <Tooltip title={isContactAlreadyExist.msTeamsId}>
                      <Text ellipsis>{isContactAlreadyExist.msTeamsId}</Text>
                    </Tooltip>
                  </Col>
                </>
              )}

              {isContactAlreadyExist.googleBusinessMessagesId && (
                <>
                  <Col span={5} style={{ alignItems: "center" }}>
                    <Image
                      src={channels.GBM.logo}
                      width={20}
                      height={20}
                      preview={false}
                    />
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={18}>
                    <Tooltip
                      title={isContactAlreadyExist.googleBusinessMessagesId}
                    >
                      <Text ellipsis>
                        {isContactAlreadyExist.googleBusinessMessagesId}
                      </Text>
                    </Tooltip>
                  </Col>
                </>
              )}

              {isContactAlreadyExist.telegramId && (
                <>
                  <Col span={5}>
                    <Image
                      src={channels.TELEGRAM.logo}
                      width={20}
                      height={20}
                      preview={false}
                    />
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={18}>
                    <Tooltip title={isContactAlreadyExist.telegramId}>
                      <Text ellipsis>{isContactAlreadyExist.telegramId}</Text>
                    </Tooltip>
                  </Col>
                </>
              )}

              {isContactAlreadyExist.appleID && (
                <>
                  <Col span={5}>
                    <Image
                      src={channels.AMB.logo}
                      width={20}
                      height={20}
                      preview={false}
                    />
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={18}>
                    <Tooltip title={isContactAlreadyExist.appleID}>
                      <Text ellipsis>{isContactAlreadyExist.appleID}</Text>
                    </Tooltip>
                  </Col>
                </>
              )}

              {isContactAlreadyExist.webChatID && (
                <>
                  <Col span={5}>
                    <Image
                      src={channels.WEB.logo}
                      width={20}
                      height={20}
                      preview={false}
                    />
                  </Col>
                  <Col span={1}>:</Col>

                  <Col span={18}>
                    <Tooltip title={isContactAlreadyExist.webChatID}>
                      <Text ellipsis>{isContactAlreadyExist.webChatID}</Text>
                    </Tooltip>
                  </Col>
                </>
              )}

              {isContactAlreadyExist.instagramId && (
                <>
                  <Col span={5}>
                    <Image
                      src={channels.INSTAGRAM.logo}
                      width={20}
                      height={20}
                      preview={false}
                    />
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={18}>
                    <Tooltip title={isContactAlreadyExist.instagramId}>
                      <Text ellipsis>{isContactAlreadyExist.instagramId}</Text>
                    </Tooltip>
                  </Col>
                </>
              )}

              {isContactAlreadyExist.viberId && (
                <>
                  <Col span={5}>
                    <Image
                      src={channels.VIBER.logo}
                      width={20}
                      height={20}
                      preview={false}
                    />
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={18}>
                    <Tooltip title={isContactAlreadyExist.viberId}>
                      <Text ellipsis>{isContactAlreadyExist.viberId}</Text>
                    </Tooltip>
                  </Col>
                </>
              )}

              {isContactAlreadyExist.facebookId && (
                <>
                  <Col span={5}>
                    <Image
                      src={channels.FACEBOOK.logo}
                      width={20}
                      height={20}
                      preview={false}
                    />
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={18}>
                    <Tooltip title={isContactAlreadyExist.facebookId}>
                      <Text ellipsis>{isContactAlreadyExist.facebookId}</Text>
                    </Tooltip>
                  </Col>
                </>
              )}

              {!(
                isContactAlreadyExist.whatsappId ||
                isContactAlreadyExist.msTeamsId ||
                isContactAlreadyExist.googleBusinessMessagesId ||
                isContactAlreadyExist.telegramId ||
                isContactAlreadyExist.appleID ||
                isContactAlreadyExist.webChatID ||
                isContactAlreadyExist.instagramId ||
                isContactAlreadyExist.viberId ||
                isContactAlreadyExist.facebookId
              ) && (
                <Row>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={{ marginLeft: 85 }}
                  />
                </Row>
              )}
            </Row>
          </div>
        </>
      ),
    },
    {
      key: "3",
      label: <InfoCircleOutlined style={{ fontSize: 15, marginLeft: 7 }} />,
      children: isContactAlreadyExist && (
        <>
          <Text strong>Additional Info</Text>
          <div style={{ marginTop: 15 }}>
            <Row gutter={[0, 20]}>
              {isContactAlreadyExist.createdDate && (
                <>
                  <Col span={10}>
                    <Text>Created</Text>
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={13}>
                    <Tooltip
                      title={getUserDateTime(
                        isContactAlreadyExist.createdDate,
                        state.currentUser.user.timeZone,
                        state.currentUser.user.dateFormat
                      )}
                    >
                      <Text ellipsis>
                        {getUserDateTime(
                          isContactAlreadyExist.createdDate,
                          state.currentUser.user.timeZone,
                          state.currentUser.user.dateFormat
                        )}
                      </Text>
                    </Tooltip>
                  </Col>
                </>
              )}

              {isContactAlreadyExist.createdSource && (
                <>
                  <Col span={10}>
                    <Text>Source</Text>
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={13}>
                    <Tooltip title={isContactAlreadyExist.createdSource}>
                      <Text ellipsis>
                        {isContactAlreadyExist.createdSource}
                      </Text>
                    </Tooltip>
                  </Col>
                </>
              )}

              {isContactAlreadyExist.unsubscribedDate && (
                <>
                  <Col span={10}>
                    <Text>Unsubscribed</Text>
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={13}>
                    <Tooltip
                      title={getUserDateTime(
                        isContactAlreadyExist.unsubscribedDate,
                        state.currentUser.user.timeZone,
                        state.currentUser.user.dateFormat
                      )}
                    >
                      <Text ellipsis>
                        {getUserDateTime(
                          isContactAlreadyExist.unsubscribedDate,
                          state.currentUser.user.timeZone,
                          state.currentUser.user.dateFormat
                        )}
                      </Text>
                    </Tooltip>
                  </Col>
                </>
              )}

              {currentConversation.last_modified && (
                <>
                  <Col span={10}>
                    <Text>Last Chat</Text>
                  </Col>
                  <Col span={1}>:</Col>
                  <Col span={13}>
                    <Tooltip
                      title={getUserDateTime(
                        currentConversation.last_modified,
                        state.currentUser.user.timeZone,
                        state.currentUser.user.dateFormat
                      )}
                    >
                      <Text ellipsis>
                        {getUserDateTime(
                          currentConversation.last_modified,
                          state.currentUser.user.timeZone,
                          state.currentUser.user.dateFormat
                        )}
                      </Text>
                    </Tooltip>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Card
        type="inner"
        style={{
          backgroundColor: "#F0F2F5",
          height: "100%",
          overflowY: "auto",
        }}
      >
        <CloseOutlined
          style={{ fontSize: "18px" }}
          onClick={() => {
            dispatchReducer({
              type: "CLOSE_INFO_PANEL",
              payload: {
                component: "conversation-info-panel",
              },
            });
          }}
        />
        <Text strong style={{ fontSize: "16px", marginLeft: "21px" }}>
          Contact Info
        </Text>
        {state.apiCallStatus?.["contact-manager-associate-contact-form-panel"]
          ?.loading ? (
          <Space
            style={{
              display: "flex",
              marginTop: "80px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FullPageLoader
              component="contact-manager-associate-contact-form-panel"
              loadingMessage="Loading user details... Please wait..."
            />
          </Space>
        ) : (
          <>
            <Card style={{ marginTop: "15px" }}>
              <Row>
                {isContactAlreadyExist ? (
                  <Space size={10}>
                    <Avatar size={60} src={""} shape="square">
                      {extractInitialsFromName(
                        isContactAlreadyExist.displayName
                      )}
                    </Avatar>

                    <Text strong ellipsis>
                      {isContactAlreadyExist.displayName}
                    </Text>
                  </Space>
                ) : (
                  <>
                    <Avatar
                      size={100}
                      icon={<UserOutlined />}
                      src={""}
                      style={{ border: "1px solid #c5c5c5", margin: "auto" }}
                    />
                    <Col
                      span={24}
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      <Text strong>
                        {currentConversationChannel &&
                          currentConversationChannel.name}
                      </Text>
                    </Col>
                  </>
                )}

                {!isContactAlreadyExist && (
                  <Col span={24} style={{ textAlign: "center" }}>
                    <Text>
                      {currentConversationChannel &&
                        currentConversationChannel.conversation_id}
                    </Text>
                  </Col>
                )}

                <Col span={24} style={{ textAlign: "center" }}>
                  {!isContactAlreadyExist && (
                    <Tooltip
                      placement="topRight"
                      title={
                        state.currentUser.permission.CONVERSATION_ADD_TO_CONTACT
                          ? "Add to contact"
                          : ""
                      }
                    >
                      <Button
                        danger
                        disabled={
                          !state.currentUser.permission
                            .CONVERSATION_ADD_TO_CONTACT
                        }
                        style={{ cursor: "pointer", marginTop: "5px" }}
                        onClick={() => {
                          dispatchReducer({
                            type: "OPEN_FORM_PANEL",
                            payload: {
                              component: "contact-manager-form-panel",
                              title: "Associate Contact",
                            },
                          });
                          dispatchReducer({
                            type: "SET_FORM_MODE",
                            payload: {
                              component: "contact-manager-form",
                              mode: "add",
                            },
                          });
                          dispatchMiddleware({
                            type: "LOAD_CONVERSATION_USER_STATE",
                            payload: {
                              component:
                                "contact-manager-associate-contact-form-panel",
                            },
                          });

                          appInsights.trackEvent({
                            name: "Conversations -  Add to Contact",
                            properties: {
                              environment: mode,
                              clientId: state.currentAccount.account.client_id,
                            },
                          });
                        }}
                      >
                        Add to Contact
                      </Button>
                    </Tooltip>
                  )}
                </Col>
              </Row>
            </Card>

            {isContactAlreadyExist ? (
              <>
                <Card
                  type="inner"
                  style={{
                    marginTop: 10,
                  }}
                >
                  <Tabs
                    tabBarGutter={70}
                    items={tabPane}
                    tabBarStyle={{ borderBottom: "2px solid #f3eeee" }}
                    type="line"
                    centered
                  />
                </Card>

                <Card style={{ marginTop: "10px" }}>
                  <Space direction="vertical">
                    <Text strong>Notes</Text>
                    Capture your thoughts, ideas and remainders for this chat.
                    <Button
                      danger
                      size="small"
                      onClick={() => {
                        dispatchReducer({
                          type: "OPEN_INFO_PANEL",
                          payload: {
                            component: "conversation-notes-info-panel",
                          },
                        });
                        state.infoPanel["conversation-info-panel"]?.visible &&
                          dispatchReducer({
                            type: "CLOSE_INFO_PANEL",
                            payload: {
                              component: "conversation-info-panel",
                            },
                          });
                      }}
                    >
                      Open Notes
                    </Button>
                  </Space>
                </Card>
              </>
            ) : (
              <>
                {currentConversationChannel?.channel?.toLowerCase() !==
                  "whatsapp" &&
                  (currentConversation.mobile ||
                    currentConversation.phone_number) && (
                    <Card style={{ marginTop: "10px" }}>
                      <Row>
                        <Text strong>Phone Number</Text>
                      </Row>
                      <Text>
                        {currentConversation.mobile ||
                          currentConversation.phone_number}
                      </Text>
                    </Card>
                  )}

                <Card style={{ marginTop: "10px" }}>
                  <Row>
                    <Text strong>Last Chat</Text>
                  </Row>
                  <Text>
                    {getUserDateTime(
                      currentConversation.last_modified,
                      state.currentUser.user.timeZone,
                      state.currentUser.user.dateFormat
                    )}
                  </Text>
                </Card>
              </>
            )}

            {!isContactAlreadyExist &&
              currentConversation.location &&
              Object.keys(currentConversation.location).length !== 0 && (
                <Card style={{ marginTop: "10px" }}>
                  <Row>
                    <ImLocation
                      style={{
                        color: "#F05742",
                        marginTop: "3px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open(
                          `${URIs.GOOGLEMAP}?q=${currentConversation.location.latitude},${currentConversation.location.longitude}`
                        );
                      }}
                    />
                    <Text style={{ marginLeft: "5px" }} strong>
                      Location
                    </Text>
                  </Row>
                  <Row>
                    <Text>{currentConversation.location.city}</Text>
                  </Row>
                  <Row>
                    <Text>{currentConversation.location.region}</Text>
                    <Text>{" - " + currentConversation.location.postal}</Text>
                  </Row>
                  <Row>
                    <Text>{currentConversation.location.country_name}</Text>
                  </Row>
                </Card>
              )}

            {!isContactAlreadyExist && currentConversationBotDetails && (
              <Card style={{ marginTop: "10px" }}>
                <Text strong>Bot Details</Text>
                <Row style={{ marginTop: "10px" }}>
                  <Avatar
                    src={
                      botProfilePictures[currentConversationBotDetails.bot_icon]
                    }
                    size="small"
                    style={{ marginTop: -3 }}
                  />
                  <Text style={{ marginLeft: "10px" }}>
                    {currentConversationBotDetails.bot_name}
                  </Text>
                </Row>
              </Card>
            )}

            {!isContactAlreadyExist && (
              <Card style={{ marginTop: "10px" }}>
                <Space direction="vertical">
                  <Text strong>Notes</Text>
                  Capture your thoughts, ideas and remainders for this chat.
                  <Button
                    danger
                    size="small"
                    onClick={() => {
                      dispatchReducer({
                        type: "OPEN_INFO_PANEL",
                        payload: {
                          component: "conversation-notes-info-panel",
                        },
                      });
                      state.infoPanel["conversation-info-panel"]?.visible &&
                        dispatchReducer({
                          type: "CLOSE_INFO_PANEL",
                          payload: {
                            component: "conversation-info-panel",
                          },
                        });
                    }}
                  >
                    Open Notes
                  </Button>
                </Space>
              </Card>
            )}
          </>
        )}
      </Card>
      <ContactManagerFormPanel
        currentConversationChannel={currentConversationChannel}
        type="associate_contact"
      />
    </>
  );
};

export default ConversationInfoPanel;
