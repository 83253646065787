import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Space,
  Input,
  Divider,
  Tooltip,
  Col,
  Row,
  Modal,
  Select,
  Typography,
} from "antd";
import { useGlobalContext } from "../../contexts/globalContext";
import useClientResponsesTableColumns from "./ClientResponsesTableColumns";
import {
  ComponentLoader,
  TabLoader,
} from "../../shared-components/LoadingIndicator";
import { convertObjectToString, getUserDateTime } from "../../utils/utility";
import { RiFilterOffLine, RiFilterLine } from "react-icons/ri";
import { TiArrowSync } from "react-icons/ti";
import { BiBroadcast } from "react-icons/bi";
import {
  CloseOutlined,
  SearchOutlined,
  DownloadOutlined,
  InboxOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { appInsights } from "../../AppInsights";
import { mode } from "../../constants/env";
import ClientResponsesFilterDropdown from "./ClientResponsesFilterDropdown";
import { Translation } from "../../translation/translation";

const { confirm } = Modal;
const { Option } = Select;
const { Text } = Typography;

const ClientResponsesHeader = ({ clientResponsesColumns }) => {
  const navigate = useNavigate();

  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();
  const [customResponsesColumns, setCustomResponsesColumns] = useState();
  const [selectedResponseValues, setSelectedResponseValues] = useState();

  const { dataColumns } = useClientResponsesTableColumns();
  const translation = Translation().clientResponses.header;

  //Load users
  let users = state.accountUsers.users?.map((user) => user._id);

  const currentResponseCategory = state.clientResponses.currentResponseCategory;

  let clientResponses = state.clientResponses.responses.data;
  //For Dropdowns in the header
  let isAssignUserDropdown = clientResponsesColumns.some(
    (responseColumn) =>
      responseColumn.key === "assigned" && responseColumn.display
  );
  let isChangeStatusDropdown = clientResponsesColumns.some(
    (responseColumn) =>
      responseColumn.key === "status" && responseColumn.display
  );
  //Check if the response contains the filterable column
  let filteredClientResponsesColumns = clientResponsesColumns
    //Remove the custom filterable response columns based on the table columns already containing filters
    .filter((responseColumn) => {
      return !dataColumns.some(
        (dataColumn) =>
          dataColumn.key === responseColumn.key && dataColumn.filters
      );
    })
    .filter((responseColumn) => responseColumn.filter);

  //Selected row export
  const selectedRowKeys =
    state.filters.selectedRowKeys["client-response-table-selected-row"];

  if (selectedRowKeys?.length > 0) {
    clientResponses = clientResponses?.filter((response) =>
      selectedRowKeys?.includes(response._id)
    );
  }

  // Filter response from whatsapp channel only for broadcasting
  const filteredWhatsAppResponses = clientResponses
    .filter(
      ({ _id, channel }) =>
        selectedRowKeys?.includes(_id) && channel.toUpperCase() === "WHATSAPP"
    )
    .map(({ userProfileName, userPhoneNumber, conversationId }) => {
      // Check if phone number contains "+"
      if (!userPhoneNumber?.includes("+") || !conversationId?.includes("+")) {
        userPhoneNumber = "+" + (userPhoneNumber || conversationId);
      }
      return {
        "Customer Name": userProfileName,
        "WhatsApp Number": userPhoneNumber,
      };
    });

  useEffect(() => {
    setCustomResponsesColumns(filteredClientResponsesColumns);
  }, [currentResponseCategory]);

  return (
    <>
      <Row>
        <Col span={13}>
          <Space>
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search"
              value={
                state.filters.searchText["client-responses-search-text-box"] &&
                state.filters.searchText["client-responses-search-text-box"]
              }
              style={{
                width: 260,
              }}
              allowClear={{
                clearIcon: (
                  <CloseOutlined
                    onClick={() => {
                      let filteredValue =
                        state.filters.filteredInfo["client-response-table"];

                      dispatchReducer({
                        type: "RD_SET_SEARCH_TEXT",
                        payload: {
                          component: "client-responses-search-text-box",
                          searchText: undefined,
                        },
                      });

                      //convert object to string
                      filteredValue = convertObjectToString(filteredValue);

                      dispatchMiddleware({
                        type: "MWD_LOAD_RESPONSES_DATA",
                        payload: {
                          component: "client-response-table",
                          currentResponseCategory,
                          filteredValue,
                          pageNumber: 1,
                        },
                      });
                      dispatchReducer({
                        type: "RD_SET_PAGINATION",
                        payload: {
                          component: "client-response-table",
                          pagination: {
                            ...state.filters.pagination?.[
                              "client-response-table"
                            ],
                            current: 1,
                          },
                        },
                      });
                    }}
                  />
                ),
              }}
              onChange={(e) => {
                const currentValue = e.target.value;
                dispatchReducer({
                  type: "RD_SET_SEARCH_TEXT",
                  payload: {
                    component: "client-responses-search-text-box",
                    searchText: currentValue,
                  },
                });
              }}
              onPressEnter={(e) => {
                let filteredValue = {
                  ...state.filters.filteredInfo["client-response-table"],
                  ...{ searchvalue: e.target.value },
                };

                //convert object to string
                filteredValue =
                  convertObjectToString(filteredValue).concat("&search=true");

                dispatchMiddleware({
                  type: "MWD_LOAD_RESPONSES_DATA",
                  payload: {
                    component: "client-response-table",
                    currentResponseCategory,
                    filteredValue,
                    pageNumber: 1,
                  },
                });
              }}
            />

            <Divider type="vertical" />

            <Tooltip placement="top" title="Clear filter(s)">
              <Button
                disabled={
                  state.filters.filteredInfo["client-response-table"]
                    ? !Object.keys(
                        Object.keys(
                          state.filters.filteredInfo["client-response-table"]
                        ).reduce(
                          (acc, key) =>
                            state.filters.filteredInfo["client-response-table"][
                              key
                            ]
                              ? ((acc[key] =
                                  state.filters.filteredInfo[
                                    "client-response-table"
                                  ][key]),
                                acc)
                              : acc,
                          {}
                        )
                      ).length
                    : true
                }
                icon={<RiFilterOffLine style={{ margin: -2 }} />}
                onClick={() => {
                  setSelectedResponseValues();
                  dispatchReducer({
                    type: "RD_SET_FILTERED_INFO",
                    payload: {
                      component: "client-response-table",
                      filteredInfo: {},
                    },
                  });
                  dispatchMiddleware({
                    type: "MWD_LOAD_RESPONSES_DATA",
                    payload: {
                      component: "client-response-table",
                      currentResponseCategory,
                      pageNumber: 1,
                    },
                  });
                  dispatchReducer({
                    type: "RD_SET_SEARCH_TEXT",
                    payload: {
                      component: "client-responses-search-text-box",
                      searchText: undefined,
                    },
                  });
                  dispatchReducer({
                    type: "RD_SET_PAGINATION",
                    payload: {
                      component: "client-response-table",
                      pagination: {
                        ...state.filters.pagination?.["client-response-table"],
                        current: 1,
                      },
                    },
                  });
                }}
              />
            </Tooltip>

            {/* Client responses export*/}

            {state.apiCallStatus["client-responses-export-button"]?.loading ? (
              <TabLoader
                component="client-responses-export-button"
                errorMessage="Error exporting responses"
              />
            ) : (
              <Tooltip
                placement="top"
                title={
                  state.currentUser.permission.RESPONSES_EXPORT
                    ? "Export Responses"
                    : "You do not have required permission"
                }
              >
                <Button
                  disabled={
                    state.clientResponses.responses.data.length === 0 ||
                    !state.currentUser.permission.RESPONSES_EXPORT
                  }
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    appInsights.trackEvent({
                      name: "Responses Export",
                      properties: {
                        environment: mode,
                        clientId: state.currentAccount.account.client_id,
                      },
                    });
                    // If selected any response then export
                    if (!selectedRowKeys || selectedRowKeys?.length === 0) {
                      let filteredValue =
                        state.filters.filteredInfo["client-response-table"];
                      if (filteredValue) {
                        //convert object to string
                        filteredValue = convertObjectToString(filteredValue);
                      }

                      dispatchMiddleware({
                        type: "MWD_LOAD_RESPONSES_DATA",
                        payload: {
                          component: "client-responses-export-button",
                          currentResponseCategory,
                          filteredValue,
                          clientResponsesColumns,
                          searchvalue: state.filters.searchText[
                            "client-responses-search-text-box"
                          ]
                            ? state.filters.searchText[
                                "client-responses-search-text-box"
                              ].concat("&search=true")
                            : "",
                          eventType: "exportResponses",
                        },
                      });
                    } else {
                      const expectedResponseTitle = [];

                      //Remove comma from clientResponse values
                      clientResponses = JSON.stringify(clientResponses);

                      clientResponses = clientResponses?.replace(
                        /(?=,(?!"))(,(?!{))/g,
                        ""
                      );

                      clientResponses = JSON.parse(clientResponses);

                      clientResponsesColumns.forEach((column) => {
                        // Check if the value is an object and needs to be split
                        if (
                          !Array.isArray(column.value) &&
                          typeof column.value === "object" &&
                          column.value !== null
                        ) {
                          // Flatten the nested object by creating a separate entry for each key-value pair
                          Object.entries(column.value).forEach(
                            ([key, value]) => {
                              expectedResponseTitle.push({
                                key: key,
                                title:
                                  key &&
                                  key
                                    .replace(/([A-Z])/g, " $1")
                                    .replace(/^./, function (str) {
                                      return str.toUpperCase();
                                    }),
                                value: value || key,
                                display: column.display || true,
                              });
                            }
                          );
                        } else {
                          // Push the original column if it's not a nested object
                          expectedResponseTitle.push(column);
                        }
                      });

                      const expectedResponseFormat = clientResponses.map(
                        (order) =>
                          Object.entries(order).reduce((acc, [key, value]) => {
                            if (
                              typeof value === "object" &&
                              value !== null &&
                              !Array.isArray(value)
                            ) {
                              // Spread nested object properties into the main object
                              acc = { ...acc, ...value };
                            } else if (Array.isArray(value)) {
                              acc[key] = `"${value
                                .map((item) => item.toString())
                                .join(",")}"`;
                            } else {
                              acc[key] = value;
                            }
                            return acc;
                          }, {})
                      );

                      //Combain CSV Title with values
                      const commaSeparatedString = [
                        expectedResponseTitle
                          .map(({ title }) => title)
                          .join(","),
                        expectedResponseFormat
                          .map((response) => {
                            return {
                              ...response,
                              leadDate: getUserDateTime(
                                response.responseDate || response.leadDate,
                                state.currentUser.user.timeZone,
                                state.currentUser.user.dateFormat
                              ),
                            };
                          })

                          .map((response) =>
                            expectedResponseTitle
                              .map(({ key, value }) => {
                                if (
                                  !Array.isArray(value) &&
                                  typeof value === "object" &&
                                  value !== null
                                ) {
                                  return value[key] || "";
                                } else {
                                  return response[key] || "";
                                }
                              })
                              .join(",")
                          )
                          .join("\n"),
                      ].join("\n");

                      //Create URL for export
                      const a = document.createElement("a");
                      a.href = URL.createObjectURL(
                        new Blob([commaSeparatedString], {
                          type: "text/csv",
                        })
                      );
                      a.setAttribute("download", "responses.csv");
                      document.body.appendChild(a);
                      a.click();
                    }
                  }}
                />
              </Tooltip>
            )}

            <Tooltip placement="top" title="Refresh Data">
              <Button
                icon={<TiArrowSync size={18} style={{ margin: -3 }} />}
                onClick={() => {
                  let filteredValue = {
                    ...state.filters.filteredInfo["client-response-table"],
                  };

                  //convert object to string
                  filteredValue = convertObjectToString(filteredValue);
                  dispatchMiddleware({
                    type: "MWD_LOAD_RESPONSES_DATA",
                    payload: {
                      component: "client-response-table",
                      currentResponseCategory,
                      filteredValue,
                      searchvalue: state.filters.searchText?.[
                        "client-responses-search-text-box"
                      ]
                        ? state.filters.searchText[
                            "client-responses-search-text-box"
                          ].concat("&search=true")
                        : "",
                      pageNumber: 1,
                    },
                  });
                }}
              />
            </Tooltip>

            <Tooltip
              placement="top"
              title={
                state.filterPanel["client-response-table"]?.visible
                  ? "Hide advanced filter(s)"
                  : "Show advanced filter(s)"
              }
            >
              <Button
                icon={<RiFilterLine style={{ margin: -2 }} />}
                onClick={() => {
                  dispatchReducer({
                    type: "SHOW_HIDE_FILTER_PANEL",
                    payload: {
                      component: "client-response-table",
                      visible:
                        !state.filterPanel["client-response-table"]?.visible,
                    },
                  });
                }}
              />
            </Tooltip>

            {state.currentAccount.account.modules.broadcast.enabled && (
              <Tooltip
                title={
                  state.currentUser.permission.BROADCAST_CREATE
                    ? filteredWhatsAppResponses.length > 0
                      ? translation.createBroadcast.infoText
                      : translation.createBroadcast.warningText
                    : translation.noAccess
                }
              >
                <Button
                  icon={
                    <BiBroadcast
                      size={18}
                      style={{
                        margin: -3,
                      }}
                    />
                  }
                  disabled={
                    !clientResponses.length ||
                    !state.currentUser.permission.BROADCAST_CREATE ||
                    filteredWhatsAppResponses?.length < 1
                  }
                  onClick={() => {
                    // Navigate to broadcast manager
                    navigate("/broadcast-manager");
                    sessionStorage.setItem(
                      "selectedMenu",
                      "/broadcast-manager"
                    );

                    dispatchReducer({
                      type: "OPEN_FORM_PANEL",
                      payload: {
                        component: "broadcast-form-panel",
                        title: "Create New Broadcast",
                      },
                    });

                    dispatchReducer({
                      type: "SET_MULTIPLE_CONTACTS_TO_POPULATE",
                      payload: {
                        contacts: filteredWhatsAppResponses,
                      },
                    });
                    // Load Automated replies
                    !state.automatedReplies.broadcastAutomatedReplies &&
                      dispatchMiddleware({
                        type: "MWD_LOAD_AUTOMATED_REPLY_DATA",
                        payload: { component: "automated-replies-table" },
                      });

                    dispatchReducer({
                      type: "CLOSE_INFO_PANEL",
                      payload: {
                        component: "client-response-info-panel",
                      },
                    });

                    appInsights.trackEvent({
                      name: "Responses - Create Broadcast",
                      properties: {
                        environment: mode,
                        clientId: state.currentAccount.account.client_id,
                      },
                    });
                  }}
                />
              </Tooltip>
            )}

            <Tooltip
              placement="top"
              title={translation.responsesSettings.infoText}
            >
              <Button
                icon={
                  <SettingOutlined
                    style={{
                      margin: -2,
                    }}
                  />
                }
                onClick={() => {
                  // Navigate to responses settings
                  navigate("/account-settings/responses");
                  sessionStorage.setItem(
                    "selectedMenu",
                    "/account-settings/responses"
                  );
                }}
              />
            </Tooltip>
          </Space>
        </Col>

        <Col span={11}>
          <Row>
            {/* Show the assign user dropdown if user enabled it in response settings */}
            <Col span={8}>
              {isAssignUserDropdown && (
                <>
                  {state.apiCallStatus[
                    "client-responses-" + selectedRowKeys + "-assignee-dropdown"
                  ]?.loading ? (
                    <ComponentLoader
                      component={
                        "client-responses-" +
                        selectedRowKeys +
                        "-assignee-dropdown"
                      }
                      loadingMessage="Loading..."
                      errorMessage="Error updating assignee"
                    />
                  ) : (
                    <Tooltip
                      placement="topRight"
                      title={
                        state.currentUser.permission.RESPONSE_ASSIGNEDTO
                          ? selectedRowKeys?.length
                            ? "Select user"
                            : "Select at least one response"
                          : "You do not have required permission"
                      }
                    >
                      <Select
                        showSearch
                        placeholder="Assign user"
                        style={{
                          width: "95%",
                        }}
                        size="medium"
                        disabled={
                          !state.currentUser.permission.RESPONSE_ASSIGNEDTO ||
                          !selectedRowKeys?.length
                        }
                        onChange={(value) => {
                          let filteredValue = {
                            ...state.filters.filteredInfo[
                              "client-response-table"
                            ],
                          };

                          //convert object to string
                          filteredValue = convertObjectToString(filteredValue);
                          dispatchMiddleware({
                            type: "MWD_SET_CLIENT_RESPONSE_ASSIGNEE",
                            payload: {
                              component:
                                "client-responses-" +
                                selectedRowKeys +
                                "-assignee-dropdown",
                              actionRowKey: selectedRowKeys,
                              assignedTo: value,
                              filteredValue,
                              pagination: state.filters.pagination?.[
                                "client-response-table"
                              ]
                                ? state.filters.pagination[
                                    "client-response-table"
                                  ].current
                                : 1,
                            },
                          });
                        }}
                      >
                        {users?.map((user, index) => {
                          return (
                            <Option key={index} value={user}>
                              {user}
                            </Option>
                          );
                        })}
                      </Select>
                    </Tooltip>
                  )}
                </>
              )}
            </Col>

            {/* Show the change status dropdown if user enabled it in response settings */}
            <Col span={8}>
              {isChangeStatusDropdown && (
                <>
                  {state.apiCallStatus[
                    "client-responses-" + selectedRowKeys + "-status-dropdown"
                  ]?.loading ? (
                    <ComponentLoader
                      component={
                        "client-responses-" +
                        selectedRowKeys +
                        "-status-dropdown"
                      }
                      loadingMessage="Loading..."
                      errorMessage="Error updating status"
                    />
                  ) : (
                    <Tooltip
                      placement="topRight"
                      title={
                        state.currentUser.permission.RESPONSE_STATUS
                          ? selectedRowKeys?.length
                            ? "Select status"
                            : "Select at least one response"
                          : "You do not have required permission"
                      }
                    >
                      <Select
                        showSearch
                        placeholder="Change status"
                        style={{
                          width: "95%",
                        }}
                        size="medium"
                        disabled={
                          !state.currentUser.permission.RESPONSE_STATUS ||
                          !selectedRowKeys?.length
                        }
                        onChange={(value) => {
                          let filteredValue = {
                            ...state.filters.filteredInfo[
                              "client-response-table"
                            ],
                          };

                          //convert object to string
                          filteredValue = convertObjectToString(filteredValue);
                          dispatchMiddleware({
                            type: "MWD_SET_CLIENT_RESPONSE_STATUS",
                            payload: {
                              component:
                                "client-responses-" +
                                selectedRowKeys +
                                "-status-dropdown",
                              actionRowKey: selectedRowKeys,
                              status: value,
                              filteredValue,
                              pagination: state.filters.pagination
                                ? state.filters.pagination[
                                    "client-response-table"
                                  ]
                                  ? state.filters.pagination[
                                      "client-response-table"
                                    ].current
                                  : 1
                                : 1,
                            },
                          });
                        }}
                      >
                        {
                          // Response status options from db
                          (
                            (typeof state.currentAccount.account.modules
                              .responses === "object" &&
                              (state.currentAccount.account.modules.responses
                                ?.assign_status_options || [
                                "Pending",
                                "Completed",
                                "Verified",
                              ])) || ["Pending", "Completed", "Verified"] // Default response status options
                          ).map((status, index) => {
                            return (
                              <Option key={index} value={status}>
                                {status}
                              </Option>
                            );
                          })
                        }
                      </Select>
                    </Tooltip>
                  )}
                </>
              )}
            </Col>

            <Col
              span={8}
              style={{
                textAlign: "right",
              }}
            >
              {state.apiCallStatus["client-response-table-selected-row"] &&
              state.apiCallStatus["client-response-table-selected-row"]
                .loading ? (
                <ComponentLoader
                  component="client-response-table-selected-row"
                  loadingMessage="Loading..."
                  errorMessage=""
                />
              ) : (
                <Tooltip
                  placement="topRight"
                  title={
                    selectedRowKeys && selectedRowKeys.length >= 1
                      ? "Delete Responses"
                      : "Select atleast one response"
                  }
                >
                  <Button
                    type="primary"
                    disabled={
                      selectedRowKeys ? selectedRowKeys.length < 1 : true
                    }
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      confirm({
                        title: "Are you sure you want to delete the responses?",
                        content: `When clicked the OK button, ${
                          selectedRowKeys?.length
                        } 
                  response${
                    selectedRowKeys?.length > 1 ? "s" : ""
                  } will be deleted permanently`,
                        onOk() {
                          dispatchMiddleware({
                            type: "MWD_DELETE_MULTIPLE_RESPONSES",
                            payload: {
                              component: "client-response-table-selected-row",
                              selectedRowKeys,
                            },
                          });
                        },
                        onCancel() {},
                      });
                    }}
                  >
                    Delete Responses
                  </Button>
                </Tooltip>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Custom column filter */}
      {state.filterPanel["client-response-table"]?.visible && (
        <>
          {customResponsesColumns?.length !== 0 ? (
            <Row style={{ marginTop: "2%", backgroundColor: "#f5f5f5" }}>
              {customResponsesColumns?.map((clientResponseColumn, index) => (
                <Tooltip
                  placement="top"
                  title={
                    typeof clientResponseColumn.value === "object"
                      ? "Unsupported data format for filter"
                      : clientResponseColumn.title
                  }
                  key={index}
                >
                  <ClientResponsesFilterDropdown
                    clientResponseColumn={clientResponseColumn}
                    selectedResponseValues={selectedResponseValues}
                    setSelectedResponseValues={setSelectedResponseValues}
                  />
                </Tooltip>
              ))}
            </Row>
          ) : (
            <div style={{ textAlign: "center", marginTop: 20 }}>
              <InboxOutlined style={{ fontSize: 30, color: "#C0C0C0" }} />
              <div>
                <Text type="secondary"> No data</Text>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ClientResponsesHeader;
