import {
  Tag,
  Space,
  Tooltip,
  Modal,
  Image,
  Typography,
  Row,
  Col,
  Spin,
} from "antd";
import moment from "moment";
import {
  DownloadOutlined,
  DeleteOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import { ComponentLoader } from "../../shared-components/LoadingIndicator";
import { useGlobalContext } from "../../contexts/globalContext";
import {
  filterAvailableChannelInfo,
  getUserDateTime,
} from "../../utils/utility";
import { channels } from "../../constants/enums";
import { URIs } from "../../constants/env";

const { confirm } = Modal;
const { Text } = Typography;

const useBroadcastManagerTableColumns = () => {
  const [state, dispatchReducer, dispatchMiddleware] = useGlobalContext();

  const botInAction = state.currentAccount.account.bots.find(
    (bot) => bot.bot_id === state.application.botInAction
  );

  const broadcastVersion =
    botInAction?.bot_channel_info?.WHATSAPP?.waba_broadcast_version;

  const wabaProvider = botInAction?.bot_channel_info?.WHATSAPP?.waba_provider;

  const showLatestTable = !(
    broadcastVersion === 4 || wabaProvider === "INEXTLABS-CLOUD"
  );

  const dataColumns = [
    {
      title: "BROADCAST NAME",
      dataIndex: "campaignName",
      key: "campaignName",
      sorter: (a, b) => {
        let valueA = a.campaignName == null ? "" : a.campaignName;
        let valueB = b.campaignName == null ? "" : b.campaignName;
        return valueA.localeCompare(valueB);
      },
      render: (campaignName) => {
        return <Text ellipsis={true}>{campaignName ? campaignName : "-"}</Text>;
      },
      ellipsis: true,
      fixed: "left",
      width: showLatestTable ? 100 : 100,
    },
    {
      title: "CHANNEL",
      dataIndex: "channel",
      key: "channel",
      ellipsis: true,
      fixed: "left",
      align: "center",
      width: showLatestTable ? 75 : 60,
      render: (_, record) => {
        return (
          channels[record.channel] && (
            <Image
              src={channels[record.channel].logo}
              width={23}
              preview={false}
            />
          )
        );
      },
      filters: filterAvailableChannelInfo(
        channels,
        botInAction?.bot_channels
      ).map((channel) => {
        return {
          text: (
            <Space key={channel.key}>
              <Image src={channel?.logo} width={15} />
              {channel?.displayName}
            </Space>
          ),
          value: channel.key,
        };
      }),
      filteredValue:
        (state.filters.filteredInfo["broadcast-manager-table"] &&
          state.filters.filteredInfo["broadcast-manager-table"].channel) ||
        null,
    },
    {
      title: "TEMPLATE NAME",
      dataIndex: "templateName",
      key: "templateName",
      render: (_, record) => {
        return (
          <Text ellipsis={true}>
            {record.templateName + ` (${record.language})`}
          </Text>
        );
      },
      sorter: (a, b) => a.templateName.localeCompare(b.templateName),
      sortOrder:
        state.filters.sortedInfo["broadcast-manager-table"] &&
        state.filters.sortedInfo["broadcast-manager-table"].columnKey ===
          "templateName"
          ? state.filters.sortedInfo["broadcast-manager-table"].order
          : null,
      ellipsis: true,
      fixed: "left",
      width: showLatestTable ? 140 : 110,
    },
  ];

  // Render columns based on broadcast version
  if (showLatestTable) {
    // add sent date at 1st index
    dataColumns.splice(1, 0, {
      title: "SENT DATE",
      dataIndex: "sentDate",
      key: "sentDate",
      sorter: (a, b) => moment(a.sentDate).unix() - moment(b.sentDate).unix(),
      sortOrder:
        state.filters.sortedInfo["broadcast-manager-table"] &&
        state.filters.sortedInfo["broadcast-manager-table"].columnKey ===
          "sentDate"
          ? state.filters.sortedInfo["broadcast-manager-table"].order
          : null,
      render: (_, record) => {
        return getUserDateTime(
          record.sentDate,
          state.currentUser.user.timeZone,
          state.currentUser.user.dateFormat
        );
      },
      ellipsis: true,
      fixed: "left",
      width: 110,
      filters: [
        {
          text: <Space>Today</Space>,
          value: "today",
        },
        {
          text: <Space>Last 7 days</Space>,
          value: "sevendays",
        },
        {
          text: <Space>Last 30 days</Space>,
          value: "thirtydays",
        },
        {
          text: <Space>Last 60 days</Space>,
          value: "sixtydays",
        },
      ],
      filteredValue:
        (state.filters.filteredInfo["broadcast-manager-table"] &&
          state.filters.filteredInfo["broadcast-manager-table"].sentDate) ||
        null,

      filterMultiple: false,
    });

    dataColumns.push({
      title: "DELIVERY STATUS",
      dataIndex: ["sentCount", "deliveredCount", "readCount"],
      key: "deliveryStatus",
      render: (_, record) => (
        <Row gutter={[0, 8]}>
          <Col span={24}>
            {record.isCompleted === 0 && (
              <Tooltip placement="top" title="Broadcast in progress">
                <Spin size="small" style={{ marginRight: 20 }} />
              </Tooltip>
            )}
            <Tag color="blue">Sent: {record.sentCount}</Tag>
            <Tag color="orange">Delivered: {record.deliveredCount}</Tag>
            <Tag color="green">Read: {record.readCount}</Tag>
            <Tag color="red">Failed: {record.failedCount}</Tag>
          </Col>
        </Row>
      ),
      ellipsis: true,
      fixed: "left",
      align: "left",
      width: 260,
    });
  } else {
    // add started date at 1st index
    dataColumns.splice(1, 0, {
      title: "STARTED DATE",
      dataIndex: "startedDate",
      key: "startedDate",
      sorter: (a, b) =>
        moment(a.startedDate).unix() - moment(b.startedDate).unix(),
      sortOrder:
        state.filters.sortedInfo["broadcast-manager-table"] &&
        state.filters.sortedInfo["broadcast-manager-table"].columnKey ===
          "startedDate"
          ? state.filters.sortedInfo["broadcast-manager-table"].order
          : null,
      render: (_, record) => {
        return getUserDateTime(
          record.startedDate,
          state.currentUser.user.timeZone,
          state.currentUser.user.dateFormat
        );
      },
      ellipsis: true,
      fixed: "left",
      width: 75,
      filters: [
        {
          text: <Space>Today</Space>,
          value: "today",
        },
        {
          text: <Space>Last 7 days</Space>,
          value: "sevendays",
        },
        {
          text: <Space>Last 30 days</Space>,
          value: "thirtydays",
        },
        {
          text: <Space>Last 60 days</Space>,
          value: "sixtydays",
        },
      ],
      filteredValue:
        (state.filters.filteredInfo["broadcast-manager-table"] &&
          state.filters.filteredInfo["broadcast-manager-table"].startedDate) ||
        null,

      filterMultiple: false,
    });

    dataColumns.push({
      title: "CATEGORY",
      dataIndex: "category",
      key: "category",
      render: (category) => {
        return <Text ellipsis={true}>{category}</Text>;
      },
      width: 60,
    });

    dataColumns.push({
      title: "RECIPIENT COUNT",
      dataIndex: "totalCount",
      key: "totalCount",
      render: (totalCount) => {
        return <Text ellipsis={true}>{totalCount}</Text>;
      },
      width: 80,
      align: "center",
    });
  }

  const actionColumns = [
    {
      title: "ACTION",
      key: "action",
      width: showLatestTable ? 75 : 55,
      align: "center",
      render: (_, record) => {
        const uniqueKey = record.notificationID || record._id;
        return (
          <Space size={24}>
            {record.isCompleted === 0 &&
              (state.apiCallStatus["broadcast-" + uniqueKey + "-cancel"] &&
              state.apiCallStatus["broadcast-" + uniqueKey + "-cancel"]
                .loading ? (
                <ComponentLoader
                  component={"broadcast-" + uniqueKey + "-cancel"}
                  loadingMessage=""
                  errorMessage=""
                />
              ) : (
                <Tooltip
                  placement="top"
                  title={
                    state.currentUser.permission.BROADCAST_CANCEL
                      ? "Cancel Broadcast"
                      : "You do not have required permission"
                  }
                >
                  {state.currentUser.permission.BROADCAST_CANCEL ? (
                    <StopOutlined
                      style={{ color: "#F05742" }}
                      onClick={() => {
                        confirm({
                          title:
                            "Are you sure you want to cancel the broadcast?",
                          content:
                            "When clicked the OK button, the broadcast will be cancelled permanently",
                          onOk() {
                            dispatchReducer({
                              type: "RD_SET_ACTION_ROW_KEY",
                              payload: {
                                component: "broadcast-manager-table",
                                actionRowKey: uniqueKey,
                              },
                            });
                            dispatchMiddleware({
                              type: "CANCEL_BROADCAST",
                              payload: {
                                component: "broadcast-" + uniqueKey + "-cancel",
                                //orchestratorResponse is a stringified json
                                terminateBroadcast:
                                  record.orchestratorResponse &&
                                  JSON.parse(record.orchestratorResponse)
                                    .terminatePostUri,
                              },
                            });
                            dispatchReducer({
                              type: "RD_CLEAR_BROADCAST_RESPONSES_FROM_WEB_SOCKET",
                              payload: {
                                notificationId: uniqueKey,
                              },
                            });
                          },
                          onCancel() {},
                        });
                      }}
                    />
                  ) : (
                    <StopOutlined style={{ color: "#C0C0C0" }} />
                  )}
                </Tooltip>
              ))}

            {state.apiCallStatus[
              "broadcast-" + uniqueKey + "-download-report"
            ] &&
            state.apiCallStatus["broadcast-" + uniqueKey + "-download-report"]
              .loading ? (
              <ComponentLoader
                component={"broadcast-" + uniqueKey + "-download-report"}
                loadingMessage=""
                errorMessage=""
              />
            ) : showLatestTable ? (
              <Tooltip
                placement="topRight"
                title={
                  state.currentUser.permission.BROADCAST_DOWNLOAD
                    ? "Download Delivery Report"
                    : "You do not have required permission"
                }
              >
                {state.currentUser.permission.BROADCAST_DOWNLOAD ? (
                  <DownloadOutlined
                    style={{ color: "#F05742" }}
                    onClick={() => {
                      dispatchMiddleware({
                        type: "MWD_DOWNLOAD_BROADCAST_MANAGER_DELIVERY_REPORT",
                        payload: {
                          component:
                            "broadcast-" + uniqueKey + "-download-report",
                          actionRowKey: uniqueKey,
                          broadcastName: record.campaignName,
                        },
                      });
                    }}
                  />
                ) : (
                  <DownloadOutlined style={{ color: "#C0C0C0" }} />
                )}
              </Tooltip>
            ) : (
              <Tooltip placement="topRight" title="View Analytics">
                <TbBrandGoogleAnalytics
                  style={{
                    marginBottom: -2,
                    color: "#F05742",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(
                      `${URIs.WABA}/wa/manage/message-templates`,
                      "_blank"
                    );
                  }}
                />
              </Tooltip>
            )}

            {state.apiCallStatus["broadcast-" + uniqueKey + "-delete"] &&
            state.apiCallStatus["broadcast-" + uniqueKey + "-delete"]
              .loading ? (
              <ComponentLoader
                component={"broadcast-" + uniqueKey + "-delete"}
                loadingMessage=""
                errorMessage=""
              />
            ) : (
              <Tooltip
                placement="topRight"
                title={
                  state.currentUser.permission.BROADCAST_DELETE
                    ? "Delete Broadcast"
                    : "You do not have required permission"
                }
              >
                {state.currentUser.permission.BROADCAST_DELETE ? (
                  <DeleteOutlined
                    style={{ color: "#F05742" }}
                    onClick={() => {
                      dispatchReducer({
                        type: "RD_SET_ACTION_ROW_KEY",
                        payload: {
                          component: "broadcast-manager-table",
                          actionRowKey: uniqueKey,
                        },
                      });
                      confirm({
                        title: "Are you sure you want to delete the broadcast?",
                        content:
                          "When clicked the OK button, the broadcast will be deleted permanently",
                        onOk() {
                          dispatchMiddleware({
                            type: "DELETE_BROADCAST",
                            payload: {
                              component: "broadcast-" + uniqueKey + "-delete",
                              actionRowKey: uniqueKey,
                              broadcastVersion,
                            },
                          });
                        },
                        onCancel() {},
                      });
                    }}
                  />
                ) : (
                  <DeleteOutlined style={{ color: "#C0C0C0" }} />
                )}
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  return { dataColumns, actionColumns };
};

export default useBroadcastManagerTableColumns;
